exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-heliosinuvio-js": () => import("./../../../src/pages/heliosinuvio.js" /* webpackChunkName: "component---src-pages-heliosinuvio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-microsoft-365-js": () => import("./../../../src/pages/microsoft365.js" /* webpackChunkName: "component---src-pages-microsoft-365-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-spravait-js": () => import("./../../../src/pages/spravait.js" /* webpackChunkName: "component---src-pages-spravait-js" */)
}

